@tailwind base;
@tailwind components;
@tailwind utilities;

.title--2 {
  color: var(--color-primary);
  font-size: 1.8rem;
  font-weight: 600;
  text-transform: uppercase;
  font-weight: 700;
}

body {
  background-color: #f5e6d7;
}

.pdf {
  /* background-color: #fff; */
}

/* [contentEditable="true"]:empty:not(:focus)::before {
  content: attr(data-text);
  display: block;
} */

[contenteditable="true"]:empty:before {
  content: attr(data-text);
  display: block;
}

p {
  display: block;
  width: 100%;
  word-break: break-all;
}

p:focus {
  background: rgb(206, 198, 198);
  border-radius: 0.5rem;
  outline: 0;
}

.summary {
  width: 320px;
  /* height: 96px; */
}

.skill-input:focus {
  background: black;
}

.add-btn {
  width: 30px;
  height: 30px;
  margin: 0 1rem;
}

.project:last-child {
  border-bottom: none;
  border-bottom-width: 0px;
}

.hideBtn:hover .add-btn {
  display: inline-block;
}

.toolbar .tool {
  background-color: rgb(231, 224, 224);
  border: 1px solid black;
}

/* .toolbar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 1px;
  z-index: 10;
} */
p:focus + .toolbar {
  display: inline-block;
}

.skill-input:focus + .toolbar {
  display: none;
}
